<template>
  <v-container>
    <h2> {{$t('Calib Table')}}</h2>
  <v-simple-table 
    dense
    fixed-header
    height="600px"
  >
    <template v-slot:default>
       
      <thead>
        <tr>
          <th class="text-center">
            id
          </th>
          <th class="text-center">
            Probe ID
          </th>
          <th class="text-center">
            Height
          </th>
          <th class="text-center">
            Volume
          </th>
          
          <th class="text-center">
            Diff
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item, n in data"
          :key="item.name"
        >
          <td class="text-center">{{ n+1}}</td>
          <td class="text-center">{{ item.probe_id || probe_id}}</td>
          <td class="text-center">{{ item.height }}</td>
          <td class="text-center">{{ item.volume }}</td>    
          <td class="text-center">{{ item.diff}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container>
</template>

<script>
export default {
    name: "CalibTable",

    props: { 
        data: [],
        probe_id: 0
    },

    methods: {
      
    },

   

}
</script>

<style>

</style>