<template>
  <v-container>
    <v-card
      class="mx-auto"
      flat
    >
    <v-card-text>
      <v-container fluid style="background-color: #598639;" dark >
        <v-col >
          <p class="text-h5 white--text">{{$t("Calibration table")}}</p>
        </v-col>
      </v-container>
      <v-col> 
        
        <v-row >
          <v-col cols="12" md="4" order="1">
            <v-select
             style="background-color: white;"
             outlined
             v-model="probe"
             :items="probes"
             item-text="name"
             item-value="id"
             @change="loadData"
             :loading="loading"
            
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="2" order="3" order-md="2">  
            <v-btn
              v-show="probe"
              color="black"
              fab
              dark
              @click="loadData(probe)"
              class="mr-3"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
         
            <v-btn
              v-show="probe"
              color="red"
              class="white--text mt-1 "
              large
              @click="dialogDelete = true"
              :loading="loading3"
             
            >
              {{$t('Delete')}}
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" order="2" order-md="3">
            <v-file-input
              accept=".csv"
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              v-model="files"
              label="File input"
              persistent-hint
              :hint="$t('Upload a file will automatically overwrite previous data for probe ID declared in file')"
              @change="convert"
              @click:clear="clear"
              :loading="loading2"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" md="2" order="4" order-md="4">
            <v-btn
              v-show="stripes.length > 0"
              color="#196619"
              class="white--text mt-1"
              large
              @click="lookupDialog = true"
            >
              {{$t('LookUp')}}
            </v-btn>
          </v-col>
          
        </v-row>
        <v-row>
          
        </v-row>
      </v-col>
      

      <calib-table style="border-style: solid;  border-color: grey; border-radius: 0.5rem; border-width: 1px;" :data="calibrations" :date="false"/>

    </v-card-text>

    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
            >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteData"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog
        v-model="lookupDialog"
        max-width="800"
      >
        <v-card>
        <v-card-title class="text-h5">
          {{$t('Lookup calib table')}}
        </v-card-title>

        <v-card-text>
          <calib-table :data="stripes" :date="false" :probe_id="probe"/>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="orange"
            dark
            @click="lookupDialog = false"
          >
            {{$t('Close')}}
          </v-btn>
             <v-spacer></v-spacer>
             <p v-show="!probe" class="red--text">{{$t('Probe is not selected, please select one.')}}</p>
          <v-btn
            color="#196619"
            dark
            @click="uploadFile"
            :disabled="!probe"
          >
            {{$t('Save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-container>
</template>

<script>

import csvToJson from 'convert-csv-to-json'
import CalibTable from '../components/CalibTable.vue'

export default {
    name: "Calibration",
    components: {
      CalibTable
        
    },

    data() {
      return {
        files: [],
        loading: false,
        loading2: false,
        loading3: false,
        calibrations: [],
        probes: [],
        probe:null,
        stripes: [],
        lookupDialog: false,
        dialogDelete: false,
        
      }
    },

    methods: {
      async initialize() {
        try {
          this.loading = true
          const prb = await this.$http.get("/probe")
          this.probes = prb.data
          this.probes.push({id: null, name: this.$t('Unselected')})
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      },
      async loadData(id) {
        try {
          this.loading = true
          const cal = await this.$http.get(`/calibration/${id}`)
          this.calibrations = cal.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }

      },

      async uploadFile() {
        let res = null
        try {
          this.lookupDialog = false
          this.loading2 = true

          res = await this.$http.post(`/calibration/${this.probe ?? ''}`, this.stripes)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
      
        } catch (e) {
          this.loading2 = false
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } finally {
          this.loading2 = false
          await this.loadData(this.stripes[0].probe_id)
          this.clear()
        }
      },
      async convert(file) {
        const regex = new RegExp(/((")(\d+)(,)(\d+)("))/mg)
        const subst = `$3.$5`;
        const csv = await file.text()
         console.log("convert file", typeof(csv))
        const result = csv.replace(regex, subst)
        console.log("convert file",result)
        this.stripes = csvToJson.fieldDelimiter(',').formatValueByType().csvStringToJson(result)
        this.lookupDialog = true
      },
      clear () {
        this.stripes = []
        this.files = []
      },
      async deleteData () {
        try {
          this.loading3 = true
          await this.$http.delete(`/calibration/${this.probe}`)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          console.log(e)
          this.loading3 = false
        } finally {
          this.loading3 = false
          await this.loadData(this.probe)
          this.dialogDelete = false
        }
      }
    },

    computed: {

    },
    created() {
      this.initialize()
    }
    

}
</script>

<style scoped>

</style>